import theme from '../../config/theme';

const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: theme.oilslick5,
    heading: theme.oilslick,
    text: theme.oilslick,
    preFormattedText: 'rgb(245, 247, 249)',
    link: theme.highlightBlue,
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: theme.oilslick90,
    heading: '#fff',
    text: '#fff',
    preFormattedText: '#000',
    link: theme.highlightBlue,
  },
};

export { lightTheme, darkTheme };
